import { Dialog } from "@mui/material";
import { Formik, FormikProps } from "formik";

import * as yup from "yup";
import { STAFF } from "../../data/Roles";
import { DepartmentInterface } from "../../models/Department";
import { Divisions } from "../../models/Division";
import { HeadOfDivision, Staff } from "../../models/Staff";
import { User } from "../../models/User";
import { addNewUser } from "../../services/organisationRearrangement";
import { AddUsersForm } from "./AddUserForm";

const AddUsersValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(/[\w\d]+\.[\w\d]+$/, "Enter valid email")
    .required("Please input an email"),
  role: yup.string().required("Please select a role"),
  department: yup.string(),
  division: yup.string(),
});

export const AddUserDialog = ({
  addUserDialogOpen,
  setAddUserDialogOpen,
  users,
  setAddUser,
  setAddUserFail,
  roles,
  departments,
  divisions,
  staffYear,
}: {
  addUserDialogOpen: boolean;
  setAddUserDialogOpen: (open: boolean) => void;
  users: User[];
  setAddUser: (values: { email: string; role: string }, message: string) => void;
  setAddUserFail: (error: string) => void;
  roles: string[];
  departments: DepartmentInterface;
  divisions: Divisions;
  staffYear: number;
}) => {
  return (
    <Dialog
      open={addUserDialogOpen}
      onClose={() => setAddUserDialogOpen(false)}
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={{
          id: "",
          email: "",
          firstName: "",
          lastName: "",
          role: STAFF,
          department: "",
          division: "",
          university: undefined,
        }}
        onSubmit={(values: User) =>
          addNewUser(
            roles,
            {
              ...values,
              id: values.email + "@sowaustralia.com",
              email: values.email + "@sowaustralia.com",
            },
            users,
            staffYear.toString()
          )
            .then((message) => setAddUser(values, message))
            .catch((error) => setAddUserFail(error))
        }
        validationSchema={AddUsersValidationSchema}
      >
        {(props: FormikProps<Staff & HeadOfDivision>) => {
          return (
            <AddUsersForm
              roles={roles}
              departments={departments}
              divisions={divisions}
              props={props}
              handleClose={() => setAddUserDialogOpen(false)}
            />
          );
        }}
      </Formik>
    </Dialog>
  );
};
